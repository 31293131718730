<template>
	<div>
		<a-upload :accept="accept" action="/admin/projectFileUpload.do" name="file" :headers="headers" 
			v-model:file-list="fileList" 
			:data="{ name: uploadName }"
			:multiple="multiple" :max-count="limit" @preview="handlePreview"
			@remove="handleRemove" :beforeUpload="beforeUpload" @change="uploadChange">
			<a-button>
				<template #icon>
					<Icon icon="UploadOutlined" />
				</template>
				文件上传
			</a-button>
		</a-upload>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	export default {
		components: {
			Icon
		},
		emits: ['update:value'],
		props: {
			value: {},
			limit: { // 上传文件数量
				type: Number,
				default: 1
			},
			maxSize: { // 文件大小
				type: Number,
				default: 2
			},
			multiple: { // 是否允许多选
				type: Boolean,
				default: false
			},
			headers: {
				type: Object,
				default: () => {}
			},
			uploadData: {
				type: Object,
				default: () => {}
			},
			accept: {
				type: String,
				default: ''
				// default: 'application/pdf'
			}
		},
		data() {
			return {
				fileList: [],
				fileUrls: [],
				uploadName: ''
			}
		},
		watch: {
			value: {
				handler(newVal) {
					if (typeof newVal === 'string') {
						this.fileUrls = [newVal];
						this.fileList = [{
							uid: 0,
							url: newVal,
							name: newVal
						}]
					} else {
						if (newVal.length) {
							newVal.forEach((item, index) => {
								if (!this.fileUrls.includes(item)) {
									this.fileUrls.push(item);
									this.fileList.push({
										uid: index,
										url: item,
										name: item
									})
								}
							})
						} else {
							this.fileUrls = [];
							this.fileList = [];
						}
					}
				},
				deep: true,
				immediate: true,
			},
		},
		methods: {
			beforeUpload(file) {
				let type = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
				let thisFileType = type.replace('.', 'application/');
				this.file = file;
				let obj = {};
				this.uploadName = file.name
				obj.name = file.name;
				obj.url = URL.createObjectURL(file);
				this.percent = 0;
				let result = true;
				const fileType = this.accept ? this.accept.split(',') : [];
				if (fileType.length && fileType.indexOf(thisFileType) < 0) {
					this.$message.error('文件格式错误，上传失败');
					result = false;
					obj.error = true;
					return result;
				}
				if (this.maxSize && this.maxSize > 0) {
					const isLimit = file.size / 1024 / 1024 <= this.maxSize;
					if (!isLimit) {
						this.$message.error(`文件大小超过${this.maxSize}MB，上传失败。`);
						result = false;
						this.setFile(result, obj, file);
						return result;
					}
				}
				return result;
			},
			setFile(result, obj, file) {
				if (result) {
					this.imageObj = obj;
					this.fileList = [file];
				} else {
					this.imageObj = {};
				}
			},
			uploadChange(e) {
				let file = e.file;
				if (file.status === 'done' && file.response) {
					if (file.response.code === 200) {
						this.fileList.forEach(item => {
							if (item.uid === file.uid) {
								item.url = file.response.data.pdfUrl;
							}
						})
						if (this.multiple) {
							this.fileUrls.push(file.response.data.pdfUrl);
						} else {
							this.fileUrls = [file.response.data.pdfUrl];
						}
						this.$emit('update:value', this.fileUrls);
					} else {
						this.$message.error(file.response.message);
						this.handleRemove(file);
					}
				}
				if (!file.status) {
					this.handleRemove(file);
				}
			},
			handleRemove(file) {
				let index = -1;
				let url = '';
				this.fileList.forEach((item, i) => {
					if (item.uid === file.uid) {
						index = i;
						url = item.url;
					}
				})
				if (index !== -1) {
					this.fileList.splice(index, 1);
				}
				if (url) {
					this.fileUrls.splice(this.fileUrls.indexOf(url), 1);
				}
				return true;
			},
			handlePreview(file) {
				if (file.url) {
					window.open(file.url);
				}
			},
			handleCancel() {
				this.previewVisible = false;
			}
		}
	}
</script>

<style scoped>
</style>