<template>
	<span>
		<span @click="onVisible">
			<slot>
				<a-button :disabled="disabled" type="primary" ghost>导入</a-button>
			</slot>
		</span>
		<a-modal v-model:visible="importVisible" title="创建导入任务" @ok="onExportOk" @cancel="onCancel">
			<a-spin :spinning="loading">
				<a-form>
					<a-form-item label="任务名称">
						<a-input v-model:value="title" placeholder="请输入"/>
					</a-form-item>
					<a-form-item name="fileList" label="导入文件">
						<filesUpload v-model:value="fileList" :limit="limit"></filesUpload>
						<!-- <p>注：建议上传pdf格式的文件，大小不超过100M</p> -->
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</span>
</template>

<script>
	import { getTaskImport } from '@/service/modules/importAndExport.js';
	import filesUpload from '@/components/upload/importFilesUpload';
	// import 
	export default {
		components: { filesUpload },
		props: {
			type: {
			    type: String,
			    default: ''
			},
            param: {
                type: String,
                default: ''
            },
			fileUrl: {
				type: String,
				default: ''
			},
			// searchData: {
			// 	type: Object,
			// 	default: ()=> {
			// 		return {}
			// 	}
			// },
			// condition: {	
			//     type: String,
			//     default: ''
			// },
			// role: {
			//     type: Number,
			//     default: ''
			// },
			disabled: {
				type: Boolean,
				default: false
			},
			limit:{
				type:Number,
				default: 1
			}
			// isAll: { // 查询条件全导出或选择导出
			// 	type: Number,
			// 	default: false
			// }
		},
		data() {
			return {
                title: '',
				fileList: [],
                loading: false,
                importVisible: false,
            }
		},
		methods: {
			onVisible() {
				if (this.disabled) return;
				this.importVisible = true;
			},
			async onExportOk() {
				if (!this.title.trim()) {
					this.$message.warn('请输入任务名称');
                    return;
				}
				this.loading = true;
				try {
					let ret = await getTaskImport({
						title: this.title,
						type: this.type,
						filePath: this.fileList.join(","),
						param: this.param || ''
						// condition: this.condition,
						// role: this.role,
						// isAll: this.isAll,	
						// param: JSON.stringify(this.searchData)
					});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('导入任务创建成功，请前往【系统-任务中心-导入任务列表】查看进度详情并下载');
						this.title = '';
						this.fileList = []
                        this.importVisible = false;
                        // this.$emit('hiddenVisible',false);
					}
				} catch(e) {
					this.loading = false;
				}
			},
            onCancel() {
                this.importVisible = false;
				this.title = '',
				this.fileList = []
                // this.$emit('hiddenVisible',false);
            }
		}
	}
</script>
